<template>
    <div class="box">
        <!-- 头部 -->
        <headers></headers>
        <el-carousel :interval="3000">
            <el-carousel-item>
                 <img src="@/assets/homePage/en-关于盛泰banner.png" class="images">
            </el-carousel-item>
        </el-carousel>
        <!-- 中间内容区域 -->
        <div class="content">
            <h2 class="H2">R&D</h2>
            <img class="img_1" src="@/assets/about/research/en-技术研发.png" alt="">
            <div class="box_1"></div>
            <div class="text" v-for="item in dataList" :key="item.id">
                <h2>{{ item.name }}</h2>
                <p>{{ item.title }}</p>
            </div>
            <div class="imgs">
                <img class="imgs_1" src="@/assets/about/research/项目配图1.png" alt="">
                <img class="imgs_2" src="@/assets/about/research/项目配图2.png" alt="">
            </div>
            <div class="box_2"></div>
            <div class="textImg">
                <img class="img_1" src="@/assets/about/research/项目配图3.png" alt="">
                <div class="div_bottom">
                    <div v-for="item in titleList" :key="item.id">
                        <li style="font-size: 1.5rem;">{{ item.title_top }}</li>
                        <p >{{ item.title_bottom }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <foot></foot>
    </div>
    <div class="_div">
        <!-- 头部 -->
        <move_header></move_header>
        <!-- 轮播图 -->
        <van-swipe :autoplay="3000" indicator-color="white">
            <van-swipe-item>
                 <img src="@/assets/homePage/en-关于盛泰banner.png" class="images">
            </van-swipe-item>
        </van-swipe>
        <!-- 内容区域 -->
        <div class="content">
            <h2 class="H2">R&D</h2>
            <img class="img_1" src="@/assets/about/research/en-技术研发.png" alt="">
            <div class="box_1"></div>
            <div class="text" v-for="item in dataList" :key="item.id">
                <h3>{{ item.name }}</h3>
                <p>{{ item.title }}</p>
            </div>
            <div class="imgs">
                <img class="imgs_1" src="@/assets/about/research/项目配图1.png" alt="">
                <img class="imgs_2" src="@/assets/about/research/项目配图2.png" alt="">
            </div>
            <div class="box_2"></div>
            <div class="textImg">
                <!-- <img class="img_1" src="@/assets/about/research/项目配图3.png" alt=""> -->
                <div class="div_bottom">
                    <div v-for="item in titleList" :key="item.id">
                        <li>{{ item.title_top }}</li>
                        <p >{{ item.title_bottom }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <move_foot></move_foot>
    </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
    components: { foot, headers, move_header, move_foot },

    setup() {
        const route = useRoute();
        const state = reactive({
            dataList: [
                { id: 1, name: '48M+5M Dual Camera Module', title: 'It is a 48M focusable camera, the same as a 5M fixed focus camera, which is put together by a metal bracket to form a combo camera.' },
                { id: 2, name: '64M FNO 1.8x Large Aperture Module', title: "The 64M large aperture camera module is designed to increase the amount of light intake by widening the aperture of the lens to enhance the effect of taking pictures in dark night scenes." },
                { id: 3, name: '64M OIS Anti-shake Camera Module', title: "64M optical photography, using the principle of OIS to enhance the stability of videography." },
                { id: 4, name: '100M Large FNO and 1.7x Large Aperture Module', title: "The 100M large FNO has powerful light-sensitive capability which can capture images with richer details and shorten the time of taking photos." },
                { id: 5, name: '200M Large FNO and 1.97x Large Aperture Module', title: "The 200M large FNO has powerful light-sensitive capability which can capture images with richer details and shorten the time of taking photos." },
            ],
            titleList: [
                { id: 1, title_top: 'Level II Material Assessment Capability', title_bottom: 'Capabilities of material evaluation, risk identification, design optimization, new material pre-research, and experience precipitation.' },
                { id: 2, title_top: 'Capability to Develop New Technologies to Match Relevant Software and Algorithms', title_bottom: "Program modularity, out-of-focus curve monitoring test program, OIS debugging software development, light leakage test algorithm, flare test program, etc." },
                { id: 3, title_top: 'Laboratory Evaluation Capability', title_bottom: "Evaluation and analysis of flare, analytic, and IQ of the module." },
                { id: 4, title_top: 'Capabilities of Developing Equipment, Equipment Modification and Innovation', title_bottom: "Out-of-focus curve test equipment, OIS test equipment, etc." },
                { id: 5, title_top: 'Capability of Pulling Together Upstream and Downstream Resources for Collaborative Development', title_bottom: "Collaborative development of SMA motors, G+Plens verification, driver IC debugging and development." },
                { id: 6, title_top: 'Capability of Pre-researching New Technologies, Materials, Designs, Processes and Methods', title_bottom: "48M/50M/64M/OIS already have mass production capability, periscope/SMAYT/SMA AF/galvanometer and other pre-research development." },
                { id: 7, title_top: 'Failure Analysis Capability', title_bottom: "Standardize the steps of failure analysis, assist engineers to find the cause of failure accurately and quickly, project review, experience precipitation." },
                { id: 8, title_top: 'Improve Development Procedures, Import PLM System, and Promote IT-based Management', title_bottom: "" },
            ]
        });
        onMounted(() => {
        });
        return {
            ...toRefs(state),
        };
    },
};
</script>

<style scoped lang="less">
.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

.box {

    width: 100%;

    .content {
        max-width: 1920px;
        margin: 0 auto;
        width: 70%;

        .textImg {
            box-sizing: border-box;
            display: flex;

            .img_1 {
                flex: 1;
                width: 100%;
                max-height: 800px;
                height: auto;
                padding: 30px 30px 30px 0;
                box-sizing: border-box;
            }

            .div_bottom {
                flex: 3;
                padding: 30px 0;
                li {
                    font-size: 25px;
                    font-weight: bold;
                    margin: 10px 0;
                }

                p {
                    font-size: 20px;
                    margin: 0;
                    margin-left: 40px;
                    margin-bottom: 30px;
                }
            }
        }

        .imgs {
            width: 100%;
            height: 500px;
            box-sizing: border-box;

            .imgs_1 {
                height: 100%;
                width: 30%;
            }

            .imgs_2 {
                height: 100%;
                padding-left: 60px;
                width: 65%;
            }
        }

        .text {
            position: relative;
            width: 100%;
            height: 100px;
            margin: 20px 0;
            padding-left: 100px;
            h2{
                margin: 0;
            }
            p{  
                margin: 10px 0;
                font-size: 20px;
            }
        }

        .text::after {
            content: '';
            position: absolute;
            left: 30px;
            top: 12px;
            width: 40px;
            height: 40px;
            background-color: #302F30;
        }

        .box_1,
        .box_2 {
            position: relative;
            width: 100%;
            height: 40px;
            color: #fff;
            background-color: #EEEEEE;
            margin: 50px 0;
        }

        .box_1::after {
            position: absolute;
            left: 0;
            top: 0;
            content: 'Projects';
            width: 250px;
            line-height: 65px;
            font-size: 25px;
            height: 65px;
            background-color: #2D71D7;
            font-weight: bold;
            text-align: center;
        }

        .box_2::after {
            position: absolute;
            left: 0;
            top: 0;
            content: 'R&D Capabilities';
            width: 250px;
            line-height: 65px;
            font-size: 25px;
            height: 65px;
            background-color: #2D71D7;
            font-weight: bold;
            text-align: center;
        }

        .img_1 {
            width: 100%;
        }

        .H2 {
            margin-top: 50px;
            font-size: 1.7rem;
        }

        .H2::before {
            content: "";
            width: 30%;
            height: 2px;
            background-color: #c7000b;
            position: absolute;
            margin-top: 40px;
            left: 16.5%;
            transform: translateX(-50%);
            // top:55px;
            min-width: 35px;
            max-width: 35px;
        }
    }
}

.images {
    width: 100%;
    height: auto;
    max-height: 600px;
}

::v-deep .el-carousel {
    max-width: 1920px;
    margin: 0 auto;
    overflow-y: hidden;
    height: auto;
    max-height: 600px;
    // max-height: 700px;
}

::v-deep .el-carousel__container {
    height: 100% !important;
    min-height: 500px;
}

.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

::v-deep .el-card {
    border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
    .box {
        display: block;
    }

    ._div {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .box {
        display: none;
    }

    ._div {
        display: block;

        .content {
       
            width: 100%;
            padding: 30px;
            box-sizing: border-box;
            .textImg {
                box-sizing: border-box;
                display: flex;

                .img_1 {
                    flex: 1;
                    width: 100%;
                    max-width: 700px;
                    max-height: 100%;
                    padding: 30px 30px 30px 0;
                    box-sizing: border-box;
                }

                .div_bottom {
                    flex: 3;
                    padding: 30px 0;

                    li {
                        font-size: 50px;
                        font-weight: bold;
                    }

                    p {
                        font-size: 45px;
                         margin-left: 70px;
                    }
                }
            }

            .imgs {
                // width: 98%;
                height: 800px;
                box-sizing: border-box;
                margin-top: 100px;
                .imgs_1 {
                    height: 100%;
                    width: 33%;
                }

                .imgs_2 {
                    height: 100%;
                    padding-left: 30px;
                    width: 65%;
                    
                }
            }

            .text {
                position: relative;
                
                width: 80%;
                padding-left: 300px;
                height: auto;
                margin: 100px 0;
                h3{
                    font-size: 70px;
                    margin: 20px 0;
                }
                p{
                    margin: 0;padding: 0;
                    font-size: 50px;
                }
            }

            .text::after {
                content: '';
                position: absolute;
                left: 110px;
                top: 80px;
                width: 100px;
                height: 100px;
                background-color: #302F30;
            }

            .box_1,
            .box_2 {
                position: relative;
                width: 100%;
                height: 170px;
                background-color: #EEEEEE;
                margin: 50px 0;
                margin-bottom: 150px;
            }
     
            .box_1::after {
                position: absolute;
                left: 0;
                top: 0;
                content: 'Projects';
                width: 500px;
                line-height: 240px;
                font-size: 80px;
                height: 240px;
                background-color: #2D71D7;
                font-weight: bold;
                color: #fff;
                text-align: center;
            }

            .box_2::after {
           position: absolute;
                left: 0;
                top: 0;
                content: 'Projects';
                width: 500px;
                line-height: 240px;
                font-size: 80px;
                color: #fff;
                height: 240px;
                background-color: #2D71D7;
                font-weight: bold;
                text-align: center;
            }

            .img_1 {
                width: 100%;
            }
                  h2 {
        text-align: center;
        font-size: 80px;
      }
      h2::before {
        content: "";
        width: 30%;
        height: 8px;
        background-color: #c7000b;
        position: absolute;
        margin-top: 100px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100px;
        max-width: 100px;
      }
        }
    }
}
</style>